import styled from 'styled-components'

export const StyledProductListingWrapper = styled.div`
  width: 100%;

  .cards-wrap {
    gap: 10px !important;
  }

  .img-container {
    aspect-ratio: 4/3 !important;
    width: 100% !important;
    height: auto !important;
    display: flex;
  }

  .main-container {
    background: white;
    border: 0 !important;
  }

  .name {
    line-height: 16px !important;
    height: 32px !important;
    font-size: 14px !important;
    font-family: var(--brand-font-family);
    font-weight: var(--brand-font-weight-normal);
  }

  .cust_car_icon {
    top: 50% !important;
    transform: translate(0%, -50%) !important;
  }

  .cust_car_icon.right {
    margin-right: 0px !important;
    right: -68px !important;
  }

  .cust_car_icon.left {
    margin-left: 0px !important;
    left: -88px !important;
  }

  .icon-arrow {
    height: 80px;
    width: 80px;
  }

  @media screen and (max-width: 768px) {
    .cards-container {
      margin: 0 !important;
    }

    .products-container {
      margin-bottom: 0px !important;
      padding: 0px !important;
    }

    .main-container {
      width: calc((100vw - 42px) / 2) !important;
    }
  }

  @media screen and (min-width: 768px) {
    .products-container {
      margin-bottom: 0px !important;
      padding: 0px;
    }

    .cards-container {
      margin-bottom: 0px;
    }

    .card-wrapper {
      margin-bottom: 0px;
    }

    .img-hover {
      height: auto !important;
    }

    .main-container:hover {
      box-shadow: none !important;
      margin: 0 !important;

      .img-container-second {
        display: none !important;
      }

      .img-container {
        display: flex !important;
      }
    }

    .img-container {
      width: 100% !important;
      height: auto !important;
    }

    .main-container {
      width: 260px !important;
    }
    .img-hover {
      height: 250px;
    }
    .img-container {
      width: 260px;
      height: 260px;
    }
    .img-container-second {
      width: 260px;
      height: 260px;
    }
    .product-info {
      padding: 16px;
      font-family: var(--brand-font-family);
    }
    .name {
      height: 48px !important;
      font-size: 20px !important;
      line-height: 120% !important;
      width: auto !important;
    }
    .price .price-ctn {
      font-size: 36px !important;
      line-height: 100% !important;
    }
  }
`
