import noop from 'lodash/noop'
import React from 'react'

import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'
import ProductListingContext from '../../../Context/ProductListingContext'
import Responsive from '../../../HOC/Responsive'
import ProductsDesktop from './ProductsDesktop'
import ProductsMobile from './ProductsMobile'
import {StyledWrapper} from './styles'

function Products({
  isMobile = false,
  onAddToCart = noop,
  onProductClick = noop,
  handleDeepLink = noop,
  section = {},
  renderSvgArrows = false,
  productListingConfig = {},
  sectionName = '',
}) {
  return (
    <ProductListingContext.Provider value={productListingConfig}>
      <StyledWrapper className="products-container">
        <>
          {isMobile ? (
            <>
              <ProductsMobile
                section={section}
                isMobile={isMobile}
                onAddToCart={onAddToCart}
                onProductClick={onProductClick}
                handleDeepLink={handleDeepLink}
                sectionName={sectionName}
              />
            </>
          ) : (
            <ProductsDesktop
              section={section}
              isMobile={isMobile}
              onAddToCart={onAddToCart}
              onProductClick={onProductClick}
              handleDeepLink={handleDeepLink}
              scrollWidth="280"
              renderSvgArrows={renderSvgArrows}
              sectionName={sectionName}
            />
          )}
        </>
      </StyledWrapper>
    </ProductListingContext.Provider>
  )
}

export default Responsive(withCustomErrorBoundary(Products, 'Products'))
