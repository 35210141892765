import noop from 'lodash/noop'
import uniqueId from 'lodash/uniqueId'
import React, {useEffect, useState} from 'react'

import ArrowLeft from '../../../../assets/icons/ArrowLeftBlack.svg'
import ArrowRight from '../../../../assets/icons/ArrowRightBlack.svg'
import Responsive from '../../../HOC/Responsive'
import {LoadingState, SectionProductsLoadingSkeleton} from '../../../common'
import CustomCarousel from '../../../common/CustomCarousel'
import Cards from '../../AllProductsV2/Products/Card'
import {LoadingStatesContainer} from './styles'

function ProductsDesktop({
  section,
  isMobile,
  onAddToCart = noop,
  onProductClick = noop,
  handleDeepLink = noop,
  scrollWidth = '380',
  renderSvgArrows = false,
  sectionName = '',
}) {
  const {products = [], cta = {}} = section || {}

  const [showList, setShowList] = useState(true)

  useEffect(() => {
    setShowList(false)

    window.setTimeout(() => {
      setShowList(true)
    }, 200)
  }, [section?.products])

  if (!showList || section.isGetttingProducts) {
    return <SectionProductsLoadingSkeleton />
  }

  return (
    <div className="carousel-container">
      <CustomCarousel
        scrollWidth={scrollWidth}
        scrollDuration="100"
        iconLeft={ArrowLeft}
        iconRight={ArrowRight}
        renderSvgArrows={renderSvgArrows}
      >
        {products?.map((product, index) => {
          return (
            <div
              className={
                section?.label === 'Other Products'
                  ? 'other-products-container'
                  : 'cards-container'
              }
            >
              <Cards
                key={uniqueId()}
                isMobile={isMobile}
                card={product}
                cta={cta}
                onAddToCart={onAddToCart}
                onNavigation={onProductClick}
                handleDeepLink={handleDeepLink}
                position={index + 1}
                sectionName={sectionName}
              />
            </div>
          )
        })}
      </CustomCarousel>
    </div>
  )
}

export default Responsive(ProductsDesktop)
