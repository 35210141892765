import isEmpty from 'lodash/isEmpty'
import React from 'react'
import ProductFinder from '../../../ComponentsV2/Screens/HomePage/ProductFinder'
import {withCustomErrorBoundary} from '../../../utils/withCustomErrorBoundary'
import {StyledProductFinder} from './ProductFinder.styles'

function Categories({state = {}, actions = {}, rclTheme = true}) {
  const {title, subtitle, ...rest} = state || {}
  const {onProductClick, onAddToCart, onNavigation, handleCta} = actions

  if (isEmpty(state)) {
    return null
  }
  return (
    <StyledProductFinder>
      <ProductFinder
        state={rest}
        actions={{
          onAddToCard: onAddToCart,
          onNavigation: onProductClick,
          onProductClick,
          handleCta,
        }}
        rclTheme={rclTheme}
      />
    </StyledProductFinder>
  )
}

export default withCustomErrorBoundary(Categories, 'product-finder')
