import styled from 'styled-components'

export const StyledWrapper = styled.div`
  margin: 0 auto 40px;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  align-items: stretch;
  align-content: stretch;
  padding: 0 10px;

  .section-label {
    font-family: var(--brand-font-family-title);
    font-weight: var(--brand-font-weight-normal);
    font-size: 24px;
    line-height: 28px;
    color: var(--brand-primary-black-color);
  }
  .cards-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0 !important;
    margin-top: 5px;
  }
  .cards-container {
    margin-right: 5px;
    margin-bottom: 10px;
    display: flex;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 0 4px var(--brand-primary-black-light-color-o1);
  }
  .other-products-container {
    margin-right: 5px;
    margin-bottom: 10px;

    .why-this-work-item {
      .key {
        color: var(--brand-primary-green-color);
      }
    }

    .atc-btn {
      background-color: var(--brand-primary-black-color);
    }
  }

  @media (min-width: 768px) {
    margin-bottom: 100px;
    gap: 20px;
    justify-content: flex-start;
    .carousel-container {
      width: 1120px;
    }
    .section-label {
      font-size: 48px;
      line-height: 48px;
    }
    .cards-container {
      margin-right: 20px;
      border-radius: 30px;
      overflow: hidden;
    }
    .other-products-container {
      margin-right: 20px;
    }
    .cust_car_icon.left {
      margin-left: -30px;
      margin-top: -30px;
    }
    .cust_car_icon.right {
      margin-top: -30px;
      margin-right: -50px;
    }
    .cards-wrap {
      padding-left: 0 !important;
    }
  }
`
export const LoadingShimmer = styled.div`
  max-width: 1120px;
  border-radius: 30px;
  width: 100%;
  margin: auto;
  height: 647px;
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;

  @keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }
`

export const LoadMoreButton = styled.button`
  border-radius: 16px;
  padding: 14px;
  width: 100%;
  color: white;
  background: ${({bgColor}) =>
    bgColor ? bgColor : 'var(--brand-primary-color)'};
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
`

export const LoadingStatesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
`
