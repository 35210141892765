import React from 'react'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import {StyledProductListingWrapper} from './ProductListing.styles'
import Products from '../../../ComponentsV2/Screens/CategoryPage/Products'
import Responsive from '../../../ComponentsV2/HOC/Responsive'

const ProductListing = (props) => {
  const {
    products = [],
    productListingConfig = {},
    filteredProductsState: {
      products: filteredProducts = [],
      isGettingProducts = false,
    } = {},
    sectionName = '',
  } = props.state || {}

  const {onAddToCart = noop, onProductClick = noop} = props.actions || {}

  const {isMobile = false} = props

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <StyledProductListingWrapper>
      <Products
        section={{
          products: filteredProducts.length > 0 ? filteredProducts : products,
          isGettingProducts: isGettingProducts,
        }}
        onProductClick={onProductClick}
        isMobile={isMobile}
        onAddToCart={onAddToCart}
        renderSvgArrows={true}
        productListingConfig={productListingConfig}
        sectionName={sectionName}
      />
    </StyledProductListingWrapper>
  )
}
export default Responsive(ProductListing)
