import styled from 'styled-components'

export const StyledProductFinder = styled.div`
  margin-bottom: 56px;

  @media screen and (min-width: 768px) {
    margin-bottom: 96px;
    .custom_carousel_wrapper_style {
      .cust_car_icon.right.show,
      .cust_car_icon.left.show {
        margin-top: -40px;

        .icon-arrow {
          width: 60px;
          height: 60px;
        }
      }

      .cust_car_icon.left.show {
        margin-left: -10px;
      }
    }
  }
`
